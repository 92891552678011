<template>
    <b-container id="cadastro" class="height-full" fluid>
        <b-row style="padding-top: 50px">
            <b-col class="text-right">
                <b-img-lazy style="height: 76px"
                            alt="ZZVendas" fluid
                            :src=" require( '../../assets/logos/zzvendas.png') "
                ></b-img-lazy>
            </b-col>
            <b-col class="text-left d-flex align-items-center">
                <h1>Vendas</h1>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-overlay :show="loading" rounded="sm">
                    <b-form @submit="PreCadastro">

                        <h2>CADASTRO DE REVENDEDORA</h2>

                        <!-- CPF -->
                        <b-form-group
                            id="input-group-CPF"
                            label="CPF"
                            label-for="CPF"
                        >
                            <b-form-input
                                id="CPF"
                                v-model="form.CPF"
                                type="text"
                                required
                                :state="validarCPF"
                                v-mask="'###.###.###-##'"
                            ></b-form-input>
                            <b-form-invalid-feedback :state="validarCPF">
                                CPF é inválido
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <!-- Celular -->
                        <b-form-group
                            id="input-group-Celular"
                            label="Celular"
                            label-for="Celular"
                        >
                            <b-form-input
                                id="Celular"
                                v-model="form.Celular"
                                type="text"
                                required
                                v-mask="'(##)#####-####'"
                            ></b-form-input>
                        </b-form-group>

                        <!-- NOME COMPLETO -->
                        <b-form-group
                            id="input-group-Nome"
                            label="NOME COMPLETO"
                            label-for="Nome"
                        >
                            <b-form-input
                                id="Nome"
                                v-model="form.Nome"
                                type="text"
                                required
                                :state="validarNomeCompleto"
                            ></b-form-input>
                            <b-form-invalid-feedback :state="validarNomeCompleto">
                                Insira o nome e sobrenome
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <!-- E-MAIL -->
                        <b-form-group
                            id="input-group-email"
                            label="E-MAIL"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                v-model="form.Email"
                                type="email"
                                required
                                :state="validarEmail"
                            ></b-form-input>
                            <b-form-invalid-feedback :state="validarEmail">
                                O e-mail informado é inválido
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <!-- SENHA -->
                        <b-form-group
                            id="input-group-Senha"
                            label="SENHA"
                            label-for="Senha"
                        >
                            <b-form-input
                                id="Senha"
                                v-model="form.Senha"
                                type="password"
                                required
                                :state="validarSenha"
                            ></b-form-input>
                            <b-form-invalid-feedback :state="validarSenha">
                                <ul>
                                    <li>A senha precisa ter no mínimo 5 caracteres</li>
                                    <li>A senha precisa ter no mínimo 1 número</li>
                                </ul>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <!-- CONFIRMAR SENHA -->
                        <b-form-group
                            id="input-group-SenhaConfirm"
                            label="CONFIRMAR SENHA"
                            label-for="SenhaConfirm"
                        >
                            <b-form-input
                                id="SenhaConfirm"
                                v-model="form.SenhaConfirm"
                                type="password"
                                required
                                :state="validarSenhasIguais"
                            ></b-form-input>
                            <b-form-invalid-feedback :state="validarSenhasIguais">
                                As senhas não sao iguais
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <!-- SELECIONAR LOJA MADRINHA -->
                        <div id="selecao-loja-madrinha">
                            <p>SELEÇÃO DE A LOJA MADRINHA</p>
                            <ul>
                                <li>- Selecione a loja mais perto de onde você mora</li>
                                <li>- Você deve selecionar uma loja do seu estado</li>
                                <li>- Faça a pesquisa de lojas selecionando o seu Estado e depois a sua Cidade</li>
                            </ul>
                        </div>

                        <!-- MARCA -->
                        <b-form-group
                            id="input-group-id_marca"
                            label="MARCA"
                            label-for="id_marca"
                        >
                            <b-form-select id="id_marca" v-model="form.id_marca" required @change=" GetLojasParticipantes() ">
                                <b-form-select-option value="">Selecione uma opção</b-form-select-option>
                                <b-form-select-option v-for="marca in marcas_disponiveis" v-bind:key="marca.MarcaIdo" :value=" marca.MarcaIdo ">{{ marca.MarcaNome }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>

                        <!-- ESTADO -->
                        <b-form-group
                            id="input-group-estado"
                            label="ESTADO"
                            label-for="estado"
                        >
                            <b-form-select id="estado" v-model="form.estado" required>
                                <b-form-select-option value="">Selecione uma opção</b-form-select-option>
                                <b-form-select-option v-for="estado in estados_cidades_lojas_disponiveis" v-bind:key="estado.value" :value=" estado.value ">{{ estado.text }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>

                        <template v-if="estados_cidades_lojas_disponiveis[ form.estado ]">

                            <!-- CIDADE -->
                            <b-form-group
                                id="input-group-cidade"
                                label="CIDADE"
                                label-for="cidade"
                            >
                                <b-form-select id="cidade" v-model="form.cidade" required>
                                    <b-form-select-option value="">Selecione uma opção</b-form-select-option>
                                    <b-form-select-option
                                        v-for="cidade in estados_cidades_lojas_disponiveis[ form.estado ].cidades"
                                        v-bind:key="cidade.value" :value=" cidade.value "
                                    >
                                        {{ cidade.text }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>

                            <!-- LOJA MADRINHA -->
                            <template v-if="estados_cidades_lojas_disponiveis[ form.estado ].cidades[ form.cidade ]">
                                <b-form-group
                                    id="input-group-CodLoja"
                                    label="LOJA MADRINHA"
                                    label-for="CodLoja"
                                >
                                    <b-form-select id="CodLoja" v-model="form.CodLoja" required>
                                        <b-form-select-option value="">Selecione uma opção</b-form-select-option>
                                        <b-form-select-option
                                            v-for="loja in estados_cidades_lojas_disponiveis[ form.estado ].cidades[ form.cidade ].lojas"
                                            v-bind:key="loja.CodLoja" :value=" loja.CodLoja "
                                        >
                                            {{ loja.NomeLoja }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </template>
                            <template v-else>
                                <b-form-group
                                    id="input-group-CodLoja-empt"
                                    label="LOJA MADRINHA"
                                    label-for="CodLoja-empt"
                                >
                                    <b-form-select id="CodLoja-empt" required>
                                        <b-form-select-option value="">Selecione uma opção</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </template>

                        </template>
                        <template v-else>

                            <!-- CIDADE -->
                            <b-form-group
                                id="input-group-cidade-empty"
                                label="CIDADE"
                                label-for="cidade"
                            >
                                <b-form-select id="cidade-empty" v-model="form.cidade" required>
                                    <b-form-select-option value="">Selecione uma opção</b-form-select-option>
                                </b-form-select>
                            </b-form-group>

                            <!-- LOJA MADRINHA -->
                            <b-form-group
                                id="input-group-CodLoja-empty"
                                label="LOJA MADRINHA"
                                label-for="CodLoja-empty"
                            >
                                <b-form-select id="CodLoja-empty" required>
                                    <b-form-select-option value="">Selecione uma opção</b-form-select-option>
                                </b-form-select>
                            </b-form-group>

                        </template>

                        <!-- ACEITE DOS TERMOS -->
                        <b-form-group
                            id="input-group-termos_aceito"
                            label-for="termos_aceito"
                        >
                            <b-form-checkbox
                                id="termos_aceito"
                                v-model="form.termos_aceito"
                                name="termos_aceito"
                                value="true"
                                unchecked-value="false"
                                :state="validarAceiteDosTermos"
                                required
                            >
                                Aceito os
                                <router-link to="/termos-de-uso" target="_blank">Termos e Condições de Uso.</router-link>
                            </b-form-checkbox>
                            <b-form-invalid-feedback :state="validarAceiteDosTermos">
                                Você tem que ler e aceitar os termos e condições de uso.
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <!-- SUBMIT -->
                        <div style="text-align: center">
                            <b-button id="btnCadastrar" type="submit" variant="primary">CADASTRE-SE</b-button>
                        </div>

                    </b-form>
                </b-overlay>
            </b-col>
        </b-row>
        <b-row class="rodape" style="padding-top: 20px">
            <b-col class="text-right">
                <b-img-lazy style="height: 20px"
                            alt="ZZVendas" fluid
                            :src=" require( '../../assets/logos/anacapri-branco.png') "
                ></b-img-lazy>
            </b-col>
            <b-col class="text-left d-flex align-items-center">
                <b-img-lazy style="height: 15px"
                            alt="ZZVendas" fluid
                            :src=" require( '../../assets/logos/arezzo-branco.png') "
                ></b-img-lazy>
            </b-col>
        </b-row>
        <b-row class="rodape" style="padding-bottom: 20px">
            <b-col class="text-center">
                Todos os direitos reservados Arezzo&Co
            </b-col>
        </b-row>
    </b-container>
</template>
<style lang="scss">
#cadastro
{
    *
    {
        font-family: "Montserrat", Arial, Helvetica, sans-serif !important;
    }

    h1
    {
        color: #1E6B9C;
        font-family: "Nunito", Arial, Helvetica, sans-serif !important;
        line-height: 76px;
    }

    .bg-light
    {
        border-radius: 20px !important;
    }

    form
    {
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;

        h2
        {
            text-align: center;
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: bold;
        }

        #selecao-loja-madrinha
        {
            p
            {
                border-bottom: 1px solid black;
            }

            ul
            {
                padding-inline-start: 10px;

                li
                {
                    list-style: none;
                }
            }
        }

        .custom-control-label
        {
            color: black !important
        }

        input, select
        {
            border-radius: 20px;
            border: 1px solid #D6D6D6;
            box-shadow: 0 3px 5px #DEDEDE;
            height: 75px;
            padding: 20px;
        }

        #CPF, #Celular
        {
            width: 200px;
        }

        button
        {
            background-color: #1EBCD5;
            box-shadow: 0 0 10px #00000029;
            border: 0;
            border-radius: 22px;
            color: white;
            font-weight: bold;
        }
    }

    .rodape
    {
        background-color: #1EBCD5;
        color: white;
        font-size: 10px;
    }
}

@media (max-width: 767px)
{
    #cadastro
    {
        #selecao-loja-madrinha
        {
            p
            {
                font-size: 15px;
            }

            ul
            {
                padding-inline-start: 10px;

                li
                {
                    font-size: 12px;
                }
            }
        }
    }
}

@media (min-width: 768px)
{
    #cadastro
    {
        .b-overlay-wrap
        {
            margin-left: 60px;
            margin-right: 60px;

            form
            {
                padding-left: 60px;
                padding-right: 60px;
            }
        }
    }
}

</style>
<script>

import Vue from "vue";
import store from '@/store';
import {Utils} from "../../components/utils";

// Mask
import {VueMaskDirective} from 'v-mask';

Vue.directive('mask', VueMaskDirective);

export default {
    name: 'cadastro',
    mixins: [Utils],
    data() {
        return {
            loading: false,
            form: {
                CPF: '',
                Celular: '',
                Nome: '',
                Email: '',
                Senha: '',
                SenhaConfirm: '',
                id_marca: '',
                estado: '',
                cidade: '',
                CodLoja: '',
                CNPJ: '',
                termos_aceito: '',
            },
            marcas_disponiveis: [],
            estados_cidades_lojas_disponiveis: {},
        }
    },
    methods: {
        GetMarcasParticipantes() {
            this.loading = true;
            this.marcas_disponiveis = [];
            store.dispatch('GetMarcasParticipantes')
                .then((json) => {
                    if (json.Success === true) {
                        this.marcas_disponiveis = json.Result;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        GetLojasParticipantes() {
            this.loading = true;
            this.estados_cidades_lojas_disponiveis = {};

            if (this.form.id_marca) {
                store.dispatch('GetLojasParticipantes', this.form.id_marca)
                    .then((json) => {
                        const response = Vue.lodash()
                        if (json.Success === true) {

                            Vue.lodash.each(json.Result, (loja) => {

                                // Carregar Estados
                                if (Vue.lodash.has(this.estados_cidades_lojas_disponiveis, loja.Uf) === false) {

                                    // Criar objeto
                                    let estado = {};
                                    estado[loja.Uf] = {
                                        value: loja.Uf,
                                        text: loja.Uf,
                                        cidades: {}
                                    };

                                    // Reativo
                                    this.estados_cidades_lojas_disponiveis = Object.assign({}, this.estados_cidades_lojas_disponiveis, estado);
                                }

                                // Carregar Cidades
                                if (Vue.lodash.has(this.estados_cidades_lojas_disponiveis[loja.Uf].cidades, loja.Municipio) === false) {

                                    // Criar objeto
                                    let cidade = {};
                                    cidade[loja.Municipio] = {
                                        value: loja.Municipio,
                                        text: loja.Municipio,
                                        lojas: []
                                    };

                                    // Reativo
                                    this.estados_cidades_lojas_disponiveis[loja.Uf].cidades = Object.assign({}, this.estados_cidades_lojas_disponiveis[loja.Uf].cidades, cidade);
                                }

                                // Carregar loja
                                this.estados_cidades_lojas_disponiveis[loja.Uf].cidades[loja.Municipio].lojas.push(loja);

                            });

                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }

        },
        CheckCpf() {
            return store.dispatch('CheckCpf', this.form.CPF);
        },
        CheckEmail() {
            return store.dispatch('CheckEmail', this.form.Email);
        },
        PreCadastro(evt) {
            evt.preventDefault();

            this.loading = true;

            // Validar Formulário
            if (this.form.termos_aceito !== 'true') {
                this.form.termos_aceito = 'false';
            }
            else {
                // Validar CPF
                this.CheckCpf()
                    .then(json => {

                        if (json.Success === true) {
                            // Validar Email
                            this.CheckEmail()
                                .then(json => {

                                    if (json.Success === true) {

                                        // Identificar loja selecionada
                                        const loja_selecionada = Vue.lodash.find(this.estados_cidades_lojas_disponiveis[this.form.estado].cidades[this.form.cidade].lojas, (loja) => {
                                            return loja.CodLoja === this.form.CodLoja;
                                        });

                                        if (Vue.lodash.isEmpty(loja_selecionada)) {
                                            alert('Loja madrinha não identificada')
                                        }
                                        else {
                                            //CNPJ
                                            this.form.CNPJ = loja_selecionada.Cnpj;

                                            // Enviar registro
                                            store.dispatch('PreCadastro', this.form)
                                                .then((json) => {
                                                    if (json.Success === true) {
                                                        // Ir para tela de obrigado
                                                        this.$router.push({name: 'obrigado', params: {Nome: this.form.Nome}})
                                                    }
                                                    else {
                                                        alert(json.Message);
                                                    }
                                                })
                                                .finally(() => {
                                                    this.loading = false;
                                                });
                                        }

                                    }
                                    else {
                                        this.loading = false;
                                        alert(json.Message);
                                    }

                                });
                        }
                        else {
                            this.loading = false;
                            alert(json.Message);
                        }

                    });
            }

        },
    },
    computed: {
        validarCPF() {
            if (this.form.CPF.trim()) {
                return this.isCPFValid(this.form.CPF);
            }
            return null;
        },
        validarEmail() {
            if (this.form.Email.trim()) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(this.form.Email);
            }

            return null;
        },
        validarNomeCompleto() {
            const nome = this.form.Nome.trim();
            if (nome) {
                const partes = nome.split(' ');
                return partes.length > 1;
            }
            return null;
        },
        validarSenha() {
            const senha = this.form.Senha;

            if (senha.trim()) {
                // Quantidade mínima
                if (senha.length < 5) {
                    return false;
                }

                // Verificar se possui um número
                if (senha.search(/[0-9]/i) < 0) {
                    return false;
                }

                return true;
            }

            return true;
        },
        validarSenhasIguais() {
            if (this.form.Senha.trim() && this.form.SenhaConfirm.trim()) {
                return this.form.Senha === this.form.SenhaConfirm;
            }
            return null;
        },
        validarAceiteDosTermos() {
            if (this.form.termos_aceito !== '') {
                return this.form.termos_aceito === 'true';
            }
            return null;
        },
    },
    mounted() {
        document.title = 'Cadastrar usuário';
        document.getElementsByTagName('body')[0].style.backgroundColor = '#e9e9e9';
    },
    created() {
        this.GetMarcasParticipantes();
    }
}
</script>
